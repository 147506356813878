<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['acneScars'])">
      <single-answer-question
        yes-no
        :title="$t('skinAppearanceState.haveAcneScars')"
        :value="acneScars"
        :options="acneScarsOptions"
        dysfunction="skin-appearance"
        @input="onFieldChange('acneScars', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="faceScarsAppearanceVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['faceScarsAppearance'])"
      >
        <single-picture-select
          id="face-scars-appearance"
          :title="$t('label.faceScarsAppearance', [$t('scarCause.acne')])"
          :value="faceScarsAppearance"
          :options="$options.acneScarAppearanceOptions"
          hint="info.scarsAppearance"
          @input="onFieldChange('faceScarsAppearance', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SinglePictureSelect from '@/modules/questionnaire/new-design-components/questions/SinglePictureSelect';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { ACNE_SCAR_APPEARANCE_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

export default {
  name: 'ScarsTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    SinglePictureSelect,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  acneScarAppearanceOptions: ACNE_SCAR_APPEARANCE_OPTIONS,
  props: {
    acneScars: {
      type: String,
      required: true
    },
    acneScarsAiSelected: {
      type: String,
      default: ''
    },
    faceScarsAppearance: {
      type: String,
      default: ''
    },
    faceScarsAppearanceVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    acneScarsOptions() {
      return flagAiSelectedOption(YES_NO_RESPONSE_OPTIONS, this.acneScarsAiSelected);
    },
    isQuestionDividerVisible() {
      return this.faceScarsAppearanceVisible;
    }
  }
};
</script>
